import React, { createContext, useContext, useEffect, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem('lightdark') || 'system');

  useEffect(() => {
    const updateThemeBasedOnSystem = () => {
      if (theme === 'system') {
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', systemTheme);
      } else {
        document.documentElement.setAttribute('data-theme', theme);
      }
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateThemeBasedOnSystem);
    updateThemeBasedOnSystem();

    return () => window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateThemeBasedOnSystem);
  }, [theme]);

  useEffect(() => {
    localStorage.setItem('lightdark', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
