import React, { useEffect, useState } from 'react';
import { ConfigProvider, Button } from 'antd';
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl';
import ko from './langs/ko.json';
import en from './langs/en.json';
import Navbar from '../components/Navbar';
import './Contact.css'; // CSS 파일 임포트

// 언어 파일 매핑
const messages = { ko, en };

function App() {
  const [theme, setTheme] = useState('light');
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    // 테마 설정
    const savedTheme = localStorage.getItem('lightdark') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    setTheme(savedTheme);

    // 언어 설정
    const language = navigator.language.split(/[-_]/)[0]; // 브라우저 언어 설정 가져오기
    const newLocale = messages[language] ? language : 'en';
    setLocale(newLocale);
  }, []);

  return (
    <ConfigProvider theme={{ mode: theme }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <div className="App">
          <Navbar />
          <div className="content">
            <h2><FormattedMessage id="contactTitle" /></h2>
            <p><FormattedMessage id="contactDesc" /></p>
            <Button type="primary" onClick={() => window.open('https://www.gaon.xyz/contact', '_blank')}> <FormattedMessage id="contactButton" /></Button>
          </div>
        </div>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
