// Home.js
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Spin, Alert } from 'antd';
import Navbar from '../components/Navbar'; // 경로에 따라 조정하세요
import './Home.css'; // Home.css에 추가적인 스타일 정의

const { Title, Paragraph } = Typography;

function Home() {
  const [ipInfo, setIpInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetch('https://apis.uiharu.dev/ipinfo/api.php')
      .then(response => response.json())
      .then(data => {
        if (data.StatusCode === 200) {
          setIpInfo(data.data);
        } else {
          console.error('Failed to fetch IP information');
          setError('IP 정보를 가져오는 데 실패했습니다.');
        }
      })
      .catch(error => {
        console.error('Error fetching IP information:', error);
        setError('IP 정보를 가져오는 중 오류가 발생했습니다.');
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="home-container">
      <Navbar />
      <div className="content-below-navbar">
        <Spin spinning={loading} tip="Loading...">
          {error && <Alert message={error} type="error" showIcon />}
          {!loading && (
            <div>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Title level={2} className="ip-address-title">Your IP Address: {ipInfo?.IPAddress}</Title>
                  <Paragraph>This is your current IP address information.</Paragraph>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Card title="IP Information" bordered={false} className="info-card">
                    <p className="card-text">City: {ipInfo?.ipinfo.city.names.en}</p>
                    <p className="card-text">Country: {ipInfo?.ipinfo.country.names.en}</p>
                    <p className="card-text">Latitude: {ipInfo?.ipinfo.location.latitude}</p>
                    <p className="card-text">Longitude: {ipInfo?.ipinfo.location.longitude}</p>
                    <p className="card-text">ISP: {ipInfo?.ipinfo.traits.isp}</p>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Card title="User Agent" bordered={false} className="info-card">
                    <p className="card-text">{ipInfo?.useragent}</p>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
}

export default Home;
