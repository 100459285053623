import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Navbar from '../components/Navbar';
import './Contact.css'; // 스타일시트 임포트

function Docs() {
  const [docContent, setDocContent] = useState('');

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    const langPrefix = userLang.startsWith('ko') ? 'ko' : 'en';

    const mdPath = `${process.env.PUBLIC_URL}/docs/${langPrefix}.md`;
    fetch(mdPath)
      .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => setDocContent(text))
      .catch(error => console.error('Failed to load the markdown file:', error));
  }, []);

  return (
    <div className="App">
      <Navbar />
      <div className="content">
        {/* 마크다운 내용을 카드 디자인으로 보여줍니다. */}
        <div className="markdown-card">
          <ReactMarkdown>{docContent}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default Docs;
