// Navbar.js
import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom'; // useLocation 훅을 import 합니다.
const { Header } = Layout;

const Navbar = () => {
  const location = useLocation(); // 현재 경로를 가져오기 위해 useLocation 훅을 사용합니다.

  // 현재 경로에 따라 메뉴 항목의 선택 상태를 결정합니다.
  // 경로가 '/'일 때 '1'을, '/docs'일 때 '2'를, '/contact'일 때 '3'을 선택 상태로 설정합니다.
  const selectedKeys = [location.pathname === '/' ? '1' : location.pathname === '/docs' ? '2' : '3'];

  return (
    <div className="navbar-container">
      <Layout className="layout">
        <Header>
          <div className="logo" />
          {/* selectedKeys prop을 사용하여 동적으로 메뉴 항목의 선택 상태를 관리합니다. */}
          <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys}>
            <Menu.Item key="1">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/docs">Docs</Link>
            </Menu.Item>
			<Menu.Item key="3">
              <Link to="/contact">Contact</Link>
            </Menu.Item>
          </Menu>
        </Header>
      </Layout>
    </div>
  );
};

export default Navbar;
